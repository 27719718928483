<template>
  <div class="Agencypar">
    <div class="top-titles">
      <div class="title">
          <span>代理合同详情</span>/合同签字详情
      </div>
    </div>



    <div class="agencypar-box">
          <div>
            {{listpar.contractInfo.title}}
          </div>
          <div>
             {{listpar.contractInfo.content}}
          </div>

          <div>
            <span><img src="../../assets/image/company.png" alt=""></span>
            <span>签字：<i>{{listpar.member_becomesign.signInfo}}</i></span>
          </div>
    </div>


  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  data() {
    return {
            // 上传图片的地址
      upLoadUrl:config.ImgUrl,

      classactive:1,

      listpar:{},
      list:fakelist.liste,
    };
  },

  created(){
        this.getSong();
  },
  mounted() {},

  methods: {
    //查询接口
      async getSong(){
				let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        handleMode:'fetch',
        bizCatalog:'AgentContractEntity',
        workerId:this.$route.params.id,
        })
 
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.listpar = res.Result;
      },

  },
};
</script>


<style  scoped>
.top-titles{
    width: 94%;
    margin: 0 auto;
    height: 100px ;
}
.top-titles .title{
    font-size: 20px;
    height: 30px;
    font-weight: 600;
    color: #555;
}
.top-titles .title span:nth-of-type(1){font-size: 18px;}
.top-titles .title span{
    font-weight: 200;
    color: #999;
}

.agencypar-box{
  width: 60%;
  margin: 0 auto;
   text-align: center;
}
.agencypar-box div{
  width: 100%;
  font-size: 16px;
}
.agencypar-box div:nth-of-type(1){
    padding-top: 10px;
} 
.agencypar-box div:nth-of-type(2){
    padding: 50px 10px 0 ;
    text-align: left;
} 
.agencypar-box div:nth-of-type(3){
  padding-top: 80px;
 display: flex;
 justify-content: space-between;
}
.agencypar-box div span{
   font-size: 16px;
}
.agencypar-box div span img{
  width: 180px;
  height: 180px;
}
.agencypar-box div span i{
  font-size: 18px;
  color: rgba(255, 141, 26, 1);
}
</style>